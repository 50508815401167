export const whiteLogo = "https://yadea.com/_nuxt/logo.235f5093.svg"
export const blackLogo = "https://yadea.com/_nuxt/logo2.046575da.svg"

export const mainBg = "https://webcms.yadea.com.cn/uploads/KEMPER_87f2bc44c0.jpg"

export const pagesNavigation = [
    { title: "Модели"},
    { title: "Делови"},
    { title: "За нас"},
    { title: "FAQ"},
    { title: "Контакт"},
]

export const faqData = [
    {
      question: 'Дали полначот може да се вклучи на нормален приклучок (штекер)?',
      answer: 'Да, полначот се вклучува на 220V приклучок којшто се наоѓа во нашите домови.'
    },
    {
      question: 'Колку долго се полни?',
      answer: 'Полнењето од 0 до 100 проценти трае околу 6 часа.'
    },
    {
      question: 'Дали претставува проблем изложеноста на вода или возење при влажни услови?',
      answer: 'Не, батериите и целиот електричен систем е целосно заштитен од надворешни услови.'
    },
    {
      question: 'Дали има гаранција на батерија?',
      answer: 'Да, има гаранција една година.'
    },
    {
      question: 'Дали имате резервни делови?',
      answer: 'Да, располагаме со широк опсег на резервни делови.'
    },
    {
      question: 'Дали имате сопствен сервис?',
      answer: 'Да, сервис обезбеден за нашите скутери под гаранција.'
    },
    {
      question: 'Дали се возат скутерите со дозвола од Б категорија?',
      answer: 'Нашите скутери се во согласност со правилата што важат за моторцикли до 50 кубика.'
    },
  ];