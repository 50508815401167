import React, { useState } from "react";
import { Link } from "react-router-dom";
import { whiteLogo, pagesNavigation } from "../global/GlobalVariables";
// import ModelCard from "./ModelCard";
import { models } from "../models/models_data";
import "../../App.css"

const Navbar = ({ toggleSidebar, isSidebarOpen, loaded, scrollToSpecs, scrollToAbout, scrollToFaq, scrollToContact, selectModel }) => {

    const setNavRef = (title) => {
        switch (title) {
            case "За нас":
                return scrollToAbout;
            case "Делови":
                return scrollToSpecs;
            case "FAQ":
                return scrollToFaq;
            case "Контакт":
                return scrollToContact;
            default:
                break;
        }
    }

    const navs = pagesNavigation.map(nav => ({
        ...nav,
        link: setNavRef(nav.title)
    }))

    const [showModels, setShowModels] = useState(false);

    const modelCards = models.filter(model => model.available === true).map(model => ({
        name: model.title,
        image: model.gallery[0]
    }))

    const submenuWidth = `${modelCards.length * 200}px`

    const handleSidebarBtn = (link) => {
        link()
        toggleSidebar()
    }

    const handleSelectModel = (index, toggle) => {
        selectModel(index);
        if (toggle) toggleSidebar()
    };

    return (
        <nav className={`w-full fixed top-0 z-30 py-2 background-nav color-animation nav transition-up ${loaded ? "show" : ""}`}>
            <div className="container flex flex-row items-center justify-between">
                <div className="flex w-full gap-4 ml-4 sm:ml-0">
                    <button
                        className="block md:hidden cursor-pointer rounded-full color-animation text-white px-2.5 py-1 hover:bg-[#FF5E00] hover:text-white"
                        onClick={toggleSidebar}>
                        <i className="fa fa-bars text-xl"></i>
                    </button>
                    <div className="flex grow">
                        <Link to="/" >
                            <img src={whiteLogo} alt="Yadea.mk" />
                        </Link>
                    </div>
                </div>

                {/* Desktop view*/}
                <div className="hidden md:block relative">
                    <ul className="flex flex-row gap-8 lg:gap-16">
                        {navs.map((obj, index) =>
                            <li key={index} className={`font-bold hover:text-[#FF5E00] color-animation text-white relative w-14 lg:w-20`}>
                                {obj.title === "Модели" ? (
                                    <div className="relative group">
                                        {/* "Models" tab with hover effect */}
                                        <button className="hover:text-[#FF5E00]">
                                            {obj.title}
                                        </button>

                                        {/* Submenu with wider area */}
                                        <div className={`absolute mt-2 p-4 bg-white bg-black text-black shadow-lg rounded-lg opacity-0 group-hover:opacity-100 group-hover:visible transition-opacity duration-300 ease-in-out z-50`} style={{ left: '-100px', width: submenuWidth}}>
                                            <div className={`grid grid-cols-${modelCards.length} gap-4`}>
                                                {modelCards.map((model, idx) => (
                                                    <button key={idx} className="flex flex-col cursor-pointer hover:scale-105 hover:text-[#FF5E00] items-center justify-center"  onClick={() => handleSelectModel(idx, false)}>
                                                        <img src={model.image} alt={model.name} className="w-40 h-24 object-cover" />
                                                        <p className="text-center mt-2">{model.name}</p>
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <button onClick={obj.link}>
                                        {obj.title}
                                    </button>
                                )}
                            </li>
                        )}
                    </ul>
                </div>

                {/* Mobile view*/}
                <div className={`fixed z-40 top-0 left-0 bg-black text-white h-screen w-64 p-2 transform transition-transform duration-300 ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"}`}>
                    <div className="flex w-full pb-4 pt-2 border-b">
                        <div className="flex grow">
                            <Link to="/" >
                                <img src={whiteLogo} alt="Yadea.mk" />
                            </Link>
                        </div>
                        <button
                            className="block md:hidden icon cursor-pointer rounded-full px-3.5 border border-white py-1 hover:bg-[#FF5E00]"
                            onClick={toggleSidebar}>
                            <i className="fa fa-x text-sm"></i>
                        </button>
                    </div>
                    <ul className="flex flex-col text-start p-4 space-y-4 text-xl font-semibold">
                        {navs.map((obj, index) =>
                            <li key={index}>
                                {obj.title === "Модели" ? (
                                    <div>
                                        {/* On click, toggle the models list */}
                                        <button
                                            onClick={() => setShowModels(!showModels)}
                                            className="flex justify-between items-center hover:text-[#FF5E00] w-full">
                                            <p>{obj.title}</p>
                                            <i className={`fa fa-angle-right text-sm transform transition-transform ${showModels ? 'rotate-90' : ''}`}></i>
                                        </button>
                                        {/* Render the models list if showModels is true */}
                                        <div className={`overflow-hidden transition-all duration-500 ease-in-out ${showModels ? 'max-h-40' : 'max-h-0'}`}>
                                            <div className="flex flex-col gap-2 mt-2 pl-4">
                                                {modelCards.map((model, idx) => (
                                                    <button key={idx} className="hover:text-[#FF5E00] text-left" onClick={() => handleSelectModel(idx, true)}>
                                                        {model.name}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <button onClick={() => handleSidebarBtn(obj.link)} className="flex w-full justify-between items-center hover:text-[#FF5E00]">
                                        <p>{obj.title}</p>
                                        <i className="fa fa-angle-right text-sm"></i>
                                    </button>
                                )}
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar;