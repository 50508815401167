import React from "react";
import Carousel from "react-multi-carousel";
import CustomDot from "../hero/CustomDot";
import "react-multi-carousel/lib/styles.css";
import banner1 from "../global/images/banner1.jpg";
import banner2 from "../global/images/banner2.jpg";
import banner1mini from "../global/images/banner1mini.jpg";
import banner2mini from "../global/images/banner2mini.jpg";
import useWindowSize from "../global/hooks/useWindowSize";
import "./Specs.css"
import battery from "../../icons/icon_1_d2d8a83dda.png"
import controller from "../../icons/icon_2_af023c7c8b.png"
import charger from "../../icons/icon_3_5ed1d68608.png"
import body from "../../icons/icon_4_1438ec196a.png"
import motor from "../../icons/icon_5_a93423bb20.png"
import monitor from "../../icons/icon_6_63371b0026.png"
import tire from "../../icons/icon_7_fc44b98676.png"
import cable from "../../icons/icon_8_9eaf70d0a1.png"

const Specs = ({ specsRef }) => {
    const { width } = useWindowSize();

    const responsive = {
        desktop: {
            breakpoint: { max: 5000, min: 1024 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 1,
        },
    };

    const specs = [
        {
            title: "Неограничена моќ",
            icons: [
                {img: motor, desc: "TTFAR Мотор"},
                {img: monitor, desc: "TFT Дисплеј"},
                {img: body, desc: "Аеродинамичен оклоп"},
                {img: tire, desc: "Гуми отпорни на лизгање"},
            ],
            image: banner1
        },
        {
            title: "Неограничен животен век",
            icons: [
                {img: battery, desc: "Графенска батерија"},
                {img: controller, desc: "Контролeр за регенерација на енергија"},
                {img: charger, desc: "Брз полнач"},
                {img: cable, desc: "Кабел со ниска загуба"},
            ],
            image: banner2
        },
    ]
    const specsMini = [
        {
            title: "Неограничена моќ",
            icons2: [
                {img: motor, desc: "TTFAR Мотор"},
                {img: monitor, desc: "TFT Дисплеј"},
                {img: body, desc: "Аеродинамичен оклоп"},
                {img: tire, desc: "Гуми отпорни на лизгање"},
            ],
            image: banner1mini
        },
        {
            title: "Неограничен животен век",
            icons2: [
                {img: battery, desc: "Графенска батерија"},
                {img: controller, desc: "Контролeр за регенерација на енергија"},
                {img: charger, desc: "Брз полнач"},
                {img: cable, desc: "Кабел со ниска загуба"},
            ],
            image: banner2mini
        },
    ];

    return (
        <section ref={specsRef}>
            <Carousel
                additionalTransfrom={0}
                responsive={responsive}
                infinite={true}
                showDots={true}
                customDot={<CustomDot />}
                partialVisible={false}
                arrows={false}
                centerMode={false}
                autoPlay={true}
                autoPlaySpeed={5000}
                containerClass="carousel-container"
                dotListClass="custom-dot-list"
            >
                {width > 768 ? (
                    specs.map((spec, index) => (
                        <div
                            className="carousel-slide h-screen bg-cover bg-center text-left text-white flex items-center"
                            style={{
                                backgroundImage: `url(${spec.image})`,
                                padding: "0 2rem",
                            }}
                            key={index}
                        >
                            <div className="overlay-text">
                                <h1 className="text-3xl md:text-7xl text-center font-bold mb-20 text-[#FF5E00]">{spec.title}</h1>
                                <div className="grid grid-cols-2 gap-20 justify-center items-center">
                                    {spec.icons.map((icon, index) =>
                                        <div key={index} className="flex flex-col justify-center items-center text-center py-2 rounded-xl bg-[#181818] shadow-md shadow-[#818181] opacity-90">
                                            <img src={icon.img} alt="icon" className="h-20 w-20"/>
                                            <span>{icon.desc}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    specsMini.map((spec, index) => (
                        <div
                            className="carousel-slide h-screen bg-cover bg-center text-white"
                            style={{
                                backgroundImage: `url(${spec.image})`,
                                padding: "1rem",
                            }}
                            key={index}
                        >
                            <div className="overlay-text-top text-cente mx-auto">
                                <h1 className="text-4xl font-semibold mb-2 text-[#FF5E00]">{spec.title}</h1>
                            </div>
                                <div className="overlay-text-bottom text-center grid grid-cols-2 gap-4 px-2 mx-auto">
                                    {spec.icons2.map((icon, index) =>
                                        <div key={index} className="flex flex-col justify-center items-center text-center py-2 rounded-xl bg-[#181818] shadow-md shadow-[#818181] opacity-90">
                                            <img src={icon.img} alt="icon" className="h-10 w-10"/>
                                            <span>{icon.desc}</span>
                                        </div>
                                    )}
                                </div>
                        </div>
                    ))
                )}
            </Carousel>
        </section>
    );
};

export default Specs;
