import React, { useState, useEffect } from "react";
import "./Model.css";
import { models } from "./models_data";
// import bg from ""

const Model = ({ index, onClose }) => {
    const [model, setModel] = useState(null)
    const [openSpecDetails, setOpenSpecDetails] = useState(null);

    const toggleSpecDetails = (index) => {
        setOpenSpecDetails(openSpecDetails === index ? null : index);
    };

    useEffect(() => {
        setModel(models[index]);
    }, [index]);

    return (
        !!model &&
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()} style={{ scrollbarWidth: 'thin' }}>
                <div>
                    <div className="bg-[#b78670] py-3 text-black border-b border-black">
                        <h2 className="text-3xl sm:text-5xl font-semibold">{model.title}</h2>
                        <button className="close-button" onClick={onClose}>✕</button>
                    </div>
                    <div className="flex flex-col md:flex-row justify-between mt-8 gap-8 overflow-y-auto max-h-[400px] p-4" style={{ scrollbarWidth: 'thin', scrollbarColor: '#b78670 transparent'}}>
                        {/* Gallery/Carousel of images */}
                        <img key={index} src={model.gallery[0]} alt={`${model.title} - ${index + 1}`} className="gallery-image" />
                        <div className="flex flex-col w-full space-y-5">
                            {model.specs.map((spec, index) =>
                                <div key={index} className="border-2 border-black shadow-md rounded-md bg-[#b78670]">
                                    <div
                                        className="flex justify-between items-center cursor-pointer p-4 font-medium"
                                        onClick={() => toggleSpecDetails(index)}
                                    >
                                        <div className="flex flex-row items-center gap-2 text-black">
                                            <i className={`${spec.icon} text-xl`} />
                                            <h3 className="text-base sm:text-xl text-left">{spec.type}</h3>
                                        </div>
                                        <span className='w-20 text-right text-black'>{openSpecDetails === index ? (
                                            <i className='fa fa-chevron-up' />
                                        ) : (
                                            <i className='fa fa-chevron-down' />
                                        )}</span>
                                    </div>
                                    <div
                                        className={`overflow-hidden transition-all duration-300 bg-[#fdedea] ease-in-out ${openSpecDetails === index ? 'max-h-[300px]' : 'max-h-0'
                                            }`}
                                    >
                                        <div className="flex flex-col p-1 space-y-2">
                                            {spec.details.map((detail, index) =>
                                                <div key={index}>
                                                    <div className="text-gray-700 flex flex-row justify-between px-1.5 md:px-5 mt-1">
                                                        <span className="text-start">{detail.mk}</span>
                                                        <b className="text-end">{detail.value}</b>
                                                    </div>
                                                    {!!(index < spec.details.length - 1) && <hr className="mt-1"></hr>}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Model;
