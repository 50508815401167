import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { whiteLogo } from "../global/GlobalVariables";
import L from 'leaflet';
import "leaflet/dist/leaflet.css";
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import markerIconRetina from 'leaflet/dist/images/marker-icon-2x.png';
import "../../App.css";

const Footer = ({ contactRef }) => {
    useEffect(() => {
        const DefaultIcon = L.icon({
            iconUrl: markerIcon,
            iconRetinaUrl: markerIconRetina,
            shadowUrl: markerShadow,
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41]
        });

        L.Marker.prototype.options.icon = DefaultIcon;

        const map = L.map('map').setView([41.9902559, 21.4488142], 13); // Coordinates for Skopje

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(map);

        L.marker([41.9902559, 21.4488142]).addTo(map)
            .bindPopup('YADEA Skopje')
            .openPopup();

        return () => {
            map.remove(); // Clean up on unmount
        };
    }, []);

    return (
        <footer className="bg-black w-full px-4 sticky top-0 py-14" ref={contactRef}>
            <section className="max-w-7xl mx-auto flex flex-col">
                <div className="text-white flex flex-col lg:flex-row justify-between ">
                    <div className="flex flex-col justify-center lg:justify-start items-center">
                        <Link to="/" >
                            <img src={whiteLogo} alt="Yadea.mk" className="h-20 lg:h-24" />
                        </Link>
                        <span className="mt-5 lg:mt-10 ml-0 lg:ml-6 text-3xl lg:text-4xl">Electrify your life</span>
                    </div>
                    <div className="flex flex-col sm:flex-row max-w-7xl mx-auto lg:mx-0 gap-8 mt-14 lg:mt-0">
                        <div className="text-start text-base lg:text-xl font-semibold w-full lg:w-auto">
                            <div className="flex flex-row gap-2 items-center">
                                <i className="fa-solid fa-location-dot"></i>
                                <p>Ul. Bojmija 1/9, Skopje, 1000</p>
                            </div>
                            <hr className="my-2"></hr>
                            <div className="flex flex-row gap-2 items-center mb-2">
                                <i className="fa-solid fa-clock"></i>
                                <div className="flex flex-row justify-between w-full">
                                    <p>Пон-Петок:</p>
                                    <p>09:30-17:30</p>
                                </div>
                                {/* <p>Пон-Петок: <br className="block lg:hidden"></br>09:30-17:30</p> */}
                            </div>
                            <div className="flex flex-row gap-2 items-center">
                                <i className="fa-solid fa-clock"></i>
                                <div className="flex flex-row justify-between w-full">
                                    <p>Сабота:</p>
                                    <p>09:30-14:30</p>
                                </div>
                                {/* <p>Сабота: <br className="block lg:hidden"></br>09:30-14:30</p> */}
                            </div>
                            <hr className="my-2"></hr>
                            <div className="flex flex-row gap-2 items-center mb-2">
                                <i className="fa-solid fa-envelope"></i>
                                <p>yadeaskopje@gmail.com</p>
                            </div>
                            <div className="flex flex-row gap-2 items-center">
                                <i className="fa-solid fa-phone"></i>
                                <p>078/335-491</p>
                            </div>
                        </div>
                        <div id="map" className="w-full h-48 sm:w-[300px]"></div> {/* Map container */}
                    </div>
                </div>
                <hr className="mt-6 mb-2"></hr>
                <div className="flex flex-row justify-center gap-8 items-center text-white mt-6 text-3xl">
                <div className="flex flex-row gap-2 items-center mb-2 justify-center">
                        <a href="https://www.facebook.com/profile.php?id=61555931353417" target="_blank" rel="noreferrer" 
                           className="rounded-full border px-2 py-1 color-animation hover:bg-[#FF5E00] hover:text-black hover:border-black">
                            <i className="fa-brands fa-facebook mt-0.5"></i>
                        </a>
                    </div>
                    <div className="flex flex-row gap-2 items-center mb-2 justify-center">
                        <a href="https://www.instagram.com/yadea.sk/" target="_blank" rel="noreferrer" 
                           className="rounded-full border px-2 py-1 color-animation hover:bg-[#FF5E00] hover:text-black hover:border-black">
                            <i className="fa-brands fa-instagram mt-0.5"></i>
                        </a>
                    </div>
                    <div className="flex flex-row gap-2 items-center mb-2 justify-center">
                        <a href="https://yadea.com/" target="_blank" rel="noreferrer"
                           className="rounded-full border px-2 py-1 color-animation hover:bg-[#FF5E00] hover:text-black hover:border-black">
                            <i className="fa-solid fa-globe"></i>
                        </a>
                    </div>
                </div>
                <span className="mt-2 text-white">&copy;Yadea2024. All rights reserved</span>
            </section>

        </footer>
    );
};

export default Footer;
