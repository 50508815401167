import React, { useState, useEffect } from 'react';
import './Loader.css';
import { whiteLogo } from "../global/GlobalVariables"

const Loader = ({ loaderDone, height }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [isLogoLoaded, setIsLogoLoaded] = useState(false)

    useEffect(() => {
        if (isLogoLoaded) {
            const timer = setTimeout(() => {
                setIsLoading(false);
                loaderDone();
            }, 1100);  // Adjust the timeout as needed

            return () => clearTimeout(timer);
        }
    }, [isLogoLoaded, loaderDone]);

    const handleLogoLoad = () => setIsLogoLoaded(true)

    return (
        <div className={`${height} w-full bg-black flex flex-col space-y-14 items-center justify-center px-10 transition-all duration-700 transform ${isLoading ? "block opacity-100" : "hidden opacity-0"}`}>
            <img src={whiteLogo} alt='Logo' className='h-32' onLoad={handleLogoLoad} />
            <div className="battery relative">
                <i className="fa-solid fa-bolt h-full text-6xl text-white absolute inset-0 pt-1.5"></i>
                <div className="battery-level"></div>
            </div>
        </div>
    )
};

export default Loader;
