import React from "react";

const CustomDot = ({ onClick, ...rest }) => {
    const { active } = rest;
    return (
        <li
            className={`custom-dot ${active ? "active-dot" : ""}`}
            onClick={() => onClick()}
        />
    );
};

export default CustomDot