import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { models } from "../models/models_data";
import useWindowSize from "../global/hooks/useWindowSize";
import "./Hero.css";
import CustomDot from "./CustomDot";

const Hero = ({ handleImageLoad, modelsRef, selectModel }) => {
    const { width } = useWindowSize();
    
    const responsive = {
        desktop: {
            breakpoint: { max: 5000, min: 1024 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 1,
        },
    };

    const handleViewMoreClick = (index) => {
        selectModel(index);
    };

    return (
        <section className="min-h-screen">
            <Carousel
                additionalTransfrom={0}
                responsive={responsive}
                infinite={true}
                showDots={width <= 600}
                customDot={<CustomDot />}
                partialVisible={true}
                arrows={width > 600}
                centerMode={false}
                className="carousel"
                autoPlay={false}
                autoPlaySpeed={5000}
            >
                {models.map((model, index) => (
                    <div className="carousel-slide" key={model.title} ref={modelsRef}>
                        <div className="content-wrapper relative hover:cursor-pointer color-animation hover:text-[#ff5e00]">
                            <h2 className="text-center text-7xl font-semibold mb-5 mt-5 md:mt-0">{model.title}</h2>
                            {!!model.attributes?.length && (
                                <div className="flex flex-row justify-center items-center mb-5">
                                    {model.attributes.map((att, index) => (
                                        <span key={index} className="text-2xl">
                                            {att}
                                            {index < model.attributes.length - 1 && <span className="mx-2">|</span>}
                                        </span>
                                    ))}
                                </div>
                            )}
                            <img
                                src={model.gallery[0]}
                                alt="scooter"
                                onLoad={handleImageLoad}
                                className="carousel-image color-animation hover:scale-105"
                                style={{ height: !model.available ? '90%' : '' }}
                                onClick={() => handleViewMoreClick(index)}
                            />
                            {!model.available &&
                                <div className="coming-soon-overlay hover:more-red">
                                    <span className="coming-soon-text px-2">Coming Soon</span>
                                </div>
                            }
                            {/* {model.available ? (
                                !!(width > 768) &&
                                <button className="view-more-overlay gap-2 px-4" onClick={() => handleViewMoreClick(index)}>
                                    <i className="fa fa-eye eye-icon text-5xl"></i>
                                    <span className="text-3xl">Види повеќе</span>
                                </button>
                            ) : (
                                
                            )} */}

                            {!!model.available && (
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 justify-center mt-8">
                                    <div className="relative overflow-hidden price-container">
                                        {!!model.isPromoPrice && (
                                            <>
                                                <span className="diagonal-ribbon-top-left">Промо</span>
                                                <span className="diagonal-ribbon-bottom-right">Цена!</span>
                                            </>
                                        )}
                                        <div className="view-more view-more-light mx-auto">
                                            <span className="text-5xl">&#8364;{model.price}</span>
                                        </div>
                                    </div>
                                    <button
                                        className="view-more view-more-dark flex mx-auto"
                                        onClick={() => handleViewMoreClick(index)}
                                    >
                                        <i className="fa fa-eye eye-icon text-4xl"></i>
                                        <span className="text-3xl">Види повеќе</span>
                                    </button>
                                </div>
                            )}



                        </div>
                    </div>
                ))}
            </Carousel>
        </section>
    );
};

export default Hero;
