import e8s from "../global/images/e8s.png"
import gt20 from "../global/images/gt20.png"
import oris from "../global/images/oris.png"
import sparkPro from "../global/images/spark-pro.png"

export const models = [
    {
        title: "E8S",
        gallery: [e8s],
        isPromoPrice: true,
        price: "1,350",
        available: true,
        attributes: ["Елегантен", "Моќен", "Паметен"],
        specs: [
            {
                type: "Димензии", icon: "fa-solid fa-sliders", details: [
                    { value: "760мм", mk: "Ширина" },
                    { value: "1870мм", mk: "Должина" },
                    { value: "1155мм", mk: "Висина" },
                    { value: "830мм", mk: "Висина на седиште" },
                    { value: "650мм", mk: "Должина на седиште" },
                ]
            },
            {
                type: "Мотор", icon: "fa-solid fa-gear", details: [
                    { value: "TTFAR", mk: "Мотор" },
                    { value: "2000 W", mk: "Моќност" },
                    { value: "3000 W", mk: "Највисока моќност" },
                    { value: "149 Nm", mk: "Максимален вртежен момент" },
                    { value: "60 km/h", mk: "Максимална брзина" },
                ]
            },
            {
                type: "Батерија", icon: "fa-solid fa-battery", details: [
                    { value: "Графенска, 3-та ген.", mk: "Вид на батерија" },
                    { value: "38Ah", mk: "Капацитет на батерија" },
                    { value: "72V", mk: "Напон на батерија" },
                ]
            },
            {
                type: "Останато", icon: "fa-solid fa-gauge-high", details: [
                    { value: "ЛЕД", mk: "Светла" },
                ]
            }
        ]
    },
    {
        title: "Spark Pro",
        gallery: [sparkPro],
        isPromoPrice: false,
        price: "1,250",
        available: true,
        attributes: ["Прифатлив", "Агилен", "Ефикасен"],
        specs: [
            {
                type: "Димензии", icon: "fa-solid fa-sliders", details: [
                    { value: "710мм", mk: "Ширина" },
                    { value: "1910мм", mk: "Должина" },
                    { value: "1075мм", mk: "Висина" },
                    { value: "1325мм", mk: "Растојание на тркала" },
                    { value: "176кг", mk: "Максимална тежина" },
                ]
            },
            {
                type: "Мотор", icon: "fa-solid fa-gear", details: [
                    { value: "2000 W", mk: "Моќност" },
                    { value: "4000 W", mk: "Највисока моќност" },
                    { value: "56 km/h", mk: "Максимална брзина" },
                    { value: "90/90-12", mk: "Предна/Задна гума" },
                    { value: "Диск/добош", mk: "Предна/Задна сопирање" },
                ]
            },
            {
                type: "Батерија", icon: "fa-solid fa-battery", details: [
                    { value: "Графенска, 72v26ah", mk: "Вид на батерија" },
                    { value: "70-100км", mk: "Опсег на батерија" },
                    { value: "2h брзо полнење / 10h полнач", mk: "Време на полнење" },
                ]
            },
            {
                type: "Останато", icon: "fa-solid fa-gauge-high", details: [
                    { value: "ЛЕД", mk: "Светла" },
                    { value: "KEYLESS GO", mk: "Старт" },
                ]
            }
        ]
    },
    { title: "GT20", gallery: [gt20], description: "Lorem ipsum", available: false },
    { title: "Oris", gallery: [oris], description: "Lorem ipsum", available: false },
]